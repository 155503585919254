























































































































































































import Vue from "vue";
import SocialAuthenticationBtn from "@/components/auth/SocialAuthenticationBtn.vue";
import { AuthSignup } from "@/interfaces/auth/auth_signup";
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  SIGNUP_USER
} from "@/store/modules/auth/constants";
import router from "@/router";
import { TranslateResult } from "vue-i18n";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs
} from "vuelidate/lib/validators";
import { get_password_validator_regex } from "@/utils/global";
export default Vue.extend({
  name: "AuthSignup",
  components: { SocialAuthenticationBtn },
  data(): AuthSignup {
    return {
      signup_form: {
        name: "",
        mobile_number: "",
        email: "",
        password: "",
        confirm_password: "",
        terms_condition: false
      },
      show_password: false,
      show_confirm_password: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      user_data: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    }),
    name_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.signup_form.name?.$dirty) return errors;
      !this.$v.signup_form.name?.required &&
        errors.push(this.$t("rules.required"));
      return errors;
    },
    mobile_number_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.signup_form.mobile_number?.$dirty) return errors;
      !this.$v.signup_form.mobile_number?.required &&
        errors.push(this.$t("rules.required"));
      return errors;
    },
    email_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.signup_form.email?.$dirty) return errors;
      !this.$v.signup_form.email?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.signup_form.email?.email && errors.push(this.$t("rules.email"));
      return errors;
    },
    password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.signup_form.password?.$dirty) return errors;
      !this.$v.signup_form.password?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.signup_form.password?.min &&
        errors.push(this.$t("rules.password.min"));
      !this.$v.signup_form.password?.max &&
        errors.push(this.$t("rules.password.max"));
      !this.$v.signup_form.password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      return errors;
    },
    confirm_password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.signup_form.confirm_password?.$dirty) return errors;
      !this.$v.signup_form.confirm_password?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.signup_form.confirm_password?.min &&
        errors.push(this.$t("rules.password.min"));
      !this.$v.signup_form.confirm_password?.max &&
        errors.push(this.$t("rules.password.max"));
      !this.$v.signup_form.confirm_password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      !this.$v.signup_form.confirm_password?.match &&
        errors.push(this.$t("rules.password.match"));
      return errors;
    },
    terms_condition_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.signup_form.terms_condition?.$dirty) return errors;
      !this.$v.signup_form.terms_condition?.required &&
        errors.push(this.$t("rules.required"));
      return errors;
    }
  },
  methods: {
    ...mapActions("auth", {
      signup_user: SIGNUP_USER
    }),
    async submit_form() {
      this.$v.$touch();
      // Validating form
      if (!this.$v.$invalid) {
        // Split full name & extracting first & last name
        const split_name = this.signup_form.name.split(" ");
        const first_name = split_name[0];
        let last_name: string | null = null;
        if (split_name.length > 1) last_name = split_name.slice(1).join(" ");
        const obj = {
          first_name: first_name,
          last_name: last_name,
          mobile_number: this.signup_form.mobile_number,
          email: this.signup_form.email,
          password: this.signup_form.password
        };
        // console.log(obj);
        const result = await this.signup_user(obj);
        if (result) {
          await router.push({
            name: "verify-email"
          });
          this.$v.signup_form?.$reset();
        }
      }
    }
  },
  // Form validations
  validations() {
    return {
      signup_form: {
        name: {
          required
        },
        mobile_number: {
          required
        },
        email: {
          required,
          email: email
        },
        password: {
          required,
          min: minLength(8),
          max: maxLength(16),
          valid: (value: string) => get_password_validator_regex().test(value)
        },
        confirm_password: {
          required,
          min: minLength(8),
          max: maxLength(16),
          valid: (value: string) => get_password_validator_regex().test(value),
          match: sameAs("password")
        },
        terms_condition: {
          required
        }
      }
    };
  }
});
